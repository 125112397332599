import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import classnames from 'classnames';
import Link from '@App/components/navigation/Link';
import HubspotLink from '@App/components/navigation/HubspotLink';
import { findByName, ALBUMS, LIBRARY, LICENCE_REQUESTS, HUBSPOT_PAGES, PLAYLISTS, PROFILE, ORDERS } from '@App/routes';
import Dropover from '@App/components/ui/Dropover';
import MyProjectsButton from '@App/components/authentication/MyProjectsButton';
import LoginButton from '@App/components/authentication/LoginButton';
import LogoutButton from '@App/components/authentication/LogoutButton';
import { SIZE_XXL } from '@App/service/MobileWatcher';
import MyCartButton from '@App/components/cart/MyCartButton';

class TopBar extends Component {
    static propTypes = {
        root: PropTypes.string,
        mobile: PropTypes.bool.isRequired,
        authenticated: PropTypes.bool.isRequired,
        canRequestLicence: PropTypes.bool.isRequired,
        profile: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }),
    };

    static defaultProps = {
        profile: null,
        root: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.renderNameButton = this.renderNameButton.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen() {
        this.setState({ open: !this.state.open });
    }

    renderProfileButton() {
        return (
            <Link className="link" route={PROFILE.name}>
                {I18n.t('menu.authenticated.profile')}
            </Link>
        );
    }

    renderLicenceRequestButton() {
        return (
            <Link className="link" route={LICENCE_REQUESTS.name}>
                {I18n.t('menu.authenticated.licence_request')}
            </Link>
        );
    }

    renderOrdersButton() {
        return (
            <Link className="link" route={ORDERS.name}>
                {I18n.t('menu.authenticated.orders')}
            </Link>
        );
    }

    renderNameButton(props) {
        const { firstName, lastName } = this.props.profile;
        const { className, ...inheritedProps } = props;

        return (
            <button className={[className, 'dropover__button--arrow'].join(' ').trim()} {...inheritedProps}>
                {firstName} {lastName[0]}.
            </button>
        );
    }

    renderConnected() {
        const { mobile, canRequestLicence } = this.props;

        return (
            <ul className="menu__section">
                <li key="projects">
                    <MyProjectsButton />
                </li>
                {mobile
                    ? <>
                        <li key="cart"><MyCartButton asDropdownItem /></li>
                        {canRequestLicence && <li key="licence-requests">{this.renderLicenceRequestButton()}</li>}
                        <li key="orders">{this.renderOrdersButton()}</li>
                        <li key="profile">{this.renderProfileButton()}</li>
                        <li key="logout"><LogoutButton /></li>
                    </>
                    : <>
                        <li key="dropover">
                            <Dropover button={this.renderNameButton} className="dropover--bright dropover--menu">
                                {canRequestLicence && this.renderLicenceRequestButton()}
                                {this.renderOrdersButton()}
                                {this.renderProfileButton()}
                                <LogoutButton />
                            </Dropover>
                        </li>
                        <li key="cart"><MyCartButton /></li>
                    </>
                }
            </ul>
        );
    }

    renderAnonymous() {
        return (
            <ul className="menu__section">
                <li>
                    <LoginButton />
                </li>
            </ul>
        );
    }

    renderNavItem(route, parameters, menuKey = null, prefix = 'menu.primary') {
        const { root } = this.props;
        const { name } = route;

        return (
            <li className={name === root ? 'current' : undefined}>
                <Link className="link" route={name} parameters={parameters}>
                    {I18n.t(`${prefix}.${menuKey || name}`)}
                </Link>
            </li>
        );
    }

    renderHubspotNavItem(route, menuKey = null, prefix = 'menu.primary') {
        return (
            <li>
                <HubspotLink className="link" route={route}>
                    {I18n.t(`${prefix}.${menuKey || name}`)}
                </HubspotLink>
            </li>
        );
    }

    renderMobileBurgerButton() {
        return (
            <button className="navbar__burger"
                type="button"
                data-toggle="collapse"
                data-target="#navbar"
                aria-controls="navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={this.toggleOpen}
            >
                <i className="icon icon--menu" aria-hidden="true" />
                <span className="sr-only">{I18n.t('menu.expand')}</span>
            </button>
        );
    }

    render() {
        const { authenticated, mobile } = this.props;
        const { open } = this.state;

        return <nav className="navbar">
            <HubspotLink className="navbar__brand" route={HUBSPOT_PAGES.HOME}>
                <span className="sr-only">Accueil</span>
            </HubspotLink>
            {mobile ? this.renderMobileBurgerButton() : null}
            <div className={classnames('menu', { 'menu--open': open })}>
                <ul className="menu__section">
                    {this.renderNavItem(LIBRARY)}
                    {this.renderNavItem(ALBUMS)}
                    {this.renderNavItem(PLAYLISTS)}
                    {this.renderHubspotNavItem(HUBSPOT_PAGES.RATES, 'rates')}
                    {this.renderHubspotNavItem(HUBSPOT_PAGES.OFFERS, 'offers')}
                    {this.renderHubspotNavItem(HUBSPOT_PAGES.SERVICES, 'services')}
                    {this.renderHubspotNavItem(HUBSPOT_PAGES.CONTACT, 'contact')}
                </ul>
                {authenticated ? this.renderConnected() : this.renderAnonymous()}
            </div>
        </nav>;
    }
}

function getRoot(state) {
    const route = findByName(state.navigation.route);

    return route ? route.root : null;
}

export default connect(
    state => ({
        root: getRoot(state),
        authenticated: state.authentication.id !== null,
        canRequestLicence: state.authentication?.permissions?.canRequestLicence === true,
        profile: state.authentication.profile,
        mobile: state.navigation.size < SIZE_XXL,
    })
)(TopBar);
