import { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import AccessDenied from '@App/components/page/error/AccessDenied';
import Button from '@App/components/ui/Button';
import Loader from '@App/components/ui/Loader';
import BlockTitle from '@App/components/layout/BlockTitle';
import MyLicenceRequests from '@App/api/query/MyLicenceRequests.graphql';
import Query from '@App/components/api/Query';
import Link from '@App/components/navigation/Link';
import Table from '@App/components/layout/Table';
import { LICENCE_REQUEST, LICENCE_REQUEST_NEW } from '@App/routes';
import { scrollToElement } from '@App/utils/scroll';

class LicenceRequestList extends Component {
    static propTypes = {
        hasNextPage: PropTypes.bool.isRequired,
        onLoadMore: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        canRequestLicence: PropTypes.bool.isRequired,
        licenceRequests: PropTypes.arrayOf(
            PropTypes.shape(),
        ).isRequired,
    };

    constructor(props) {
        super(props);

        this.renderHead = this.renderHead.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.renderEmpty = this.renderEmpty.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading === true && this.props.loading === false) {
            const hash = window.location.hash;
            if (hash) {
                scrollToElement(document.querySelector(hash));
            }
        }
    }

    getStatusClass(status) {
        switch (status) {
            case 'pending':
                return 'status-new';
            case 'submitted':
                return 'status-pending';
            case 'processed':
                return 'status-success';
        }
    }

    renderRow(licenceRequest) {
        const { id, title, createdAt, type, status, quote, invoice } = licenceRequest;

        return (
            <tr key={id} id={`request-${id}`}>
                <td>{I18n.l('date.formats.short', createdAt)}</td>
                <td>{title}</td>
                <td>{quote}</td>
                <td>{invoice}</td>
                <td className={this.getStatusClass(status)}>
                    {I18n.t(`model.licenceRequest.status.${status}`)}
                </td>
                <td>
                    <Link route={LICENCE_REQUEST.name}
                        parameters={{ id }}
                        label={I18n.t('page.licence_requests.show')}
                        tooltip
                        className="no-underline"
                        icon="eye"/>
                </td>
            </tr>
        );
    }

    renderHead() {
        return (
            <tr>
                <th>{I18n.t('page.licence_requests.table.date')}</th>
                <th>{I18n.t('page.licence_requests.table.title')}</th>
                <th>{I18n.t('page.licence_requests.table.quote')}</th>
                <th>{I18n.t('page.licence_requests.table.invoice')}</th>
                <th>{I18n.t('page.licence_requests.table.status')}</th>
                <th>{I18n.t('page.licence_requests.table.actions')}</th>
            </tr>
        );
    }

    renderEmpty() {
        const { loading } = this.props;

        return (
            <tr>
                <td className="empty" colSpan="6">
                    {loading ? <Loader /> : I18n.t('page.licence_requests.table.empty')}
                </td>
            </tr>
        );
    }

    render() {
        if (!this.props.canRequestLicence) {
            return <AccessDenied />;
        }

        const { licenceRequests, hasNextPage, onLoadMore } = this.props;

        return (
            <div className="content">
                <div className="container-fluid">
                    <BlockTitle title={I18n.t('page.licence_requests.title')}>
                        <Link route={LICENCE_REQUEST_NEW.name} className="btn btn--primary">
                            {I18n.t('page.licence_requests.new')}
                        </Link>
                    </BlockTitle>
                    <Table
                        values={licenceRequests}
                        renderRow={this.renderRow}
                        renderHead={this.renderHead}
                        renderEmpty={this.renderEmpty}
                    />
                    {hasNextPage ? <Button onClick={onLoadMore} label={I18n.t('page.licence_requests.more')} /> : null}
                </div>
            </div>
        );
    }
}

function mapResult(data) {
    return {
        hasNextPage: data.licenceRequests.pageInfo.hasNextPage,
        licenceRequests: data.licenceRequests.edges.map(edge => edge.node),
        loading: false,
    };
}

function mapLoading() {
    return {
        hasNextPage: false,
        licenceRequests: [],
        loading: true,
    };
}

const ConnectLicenceRequestList = connect(
    state => ({
        canRequestLicence: state.authentication?.permissions?.canRequestLicence === true,
    }),
)(LicenceRequestList);

export default function LicenceRequestListWrapper(props) {
    const { limit } = props;

    return (
        <Query
            component={ConnectLicenceRequestList}
            loadingComponent={ConnectLicenceRequestList}
            query={MyLicenceRequests}
            variables={{ limit }}
            mapResult={mapResult}
            mapLoading={mapLoading}
            loadMore="licenceRequests"
        />
    );
}

LicenceRequestListWrapper.propTypes = {
    limit: PropTypes.number,
};

LicenceRequestListWrapper.defaultProps = {
    limit: 30,
};
