import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import GetTrackVersion from '@App/api/query/GetTrackVersion.graphql';
import Query from '@App/components/api/Query';
import Loader from '@App/components/ui/Loader';
import Button from '@App/components/ui/Button';
import DurationInput from '@App/components/form/DurationInput';
import { formatDuration } from '@App/utils';

class TrackListRow extends Component {
    static propTypes = {
        reference: PropTypes.string.isRequired,
        onRemove: PropTypes.func.isRequired,
        title: PropTypes.string,
        label: PropTypes.string,
        writers: PropTypes.arrayOf(PropTypes.string),
        publishers: PropTypes.arrayOf(PropTypes.string),
        duration: PropTypes.number,
        loading: PropTypes.bool,
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
        title: null,
        label: null,
        writers: null,
        publishers: null,
        duration: null,
        loading: true,
        readOnly: false,
    };

    renderRemoveButton() {
        const { onRemove } = this.props;

        return <Button onClick={onRemove} icon="trash" className=""/>;
    }

    renderDetails() {
        const { loading, title, label, writers, publishers, duration } = this.props;

        if (loading) {
            return <td colSpan={4}><Loader /></td>;
        }

        return <Fragment>
            <td key="title">{title} - {label}</td>
            <td key="writers">{writers.join(', ')}</td>
            <td key="publishers">{publishers.join(', ')}</td>
            <td key="duration">{formatDuration(duration)}</td>
        </Fragment>;
    }

    render() {
        const { reference, readOnly } = this.props;

        return (
            <tr key={reference}>
                <td>{reference}</td>
                {this.renderDetails()}
                <td className="action-cell">{readOnly ? null : this.renderRemoveButton()}</td>
            </tr>
        );
    }
}

function mapResults(data) {
    const { label, track, duration } = data.trackVersion;

    return {
        loading: false,
        title: track.title,
        writers: track.writers.map(({ name }) => name),
        publishers: track.publishers.map(({ name }) => name),
        label,
        duration,
    };
}

export default function TrackListWrapper(props) {
    const { reference, ...childProps } = props;

    return <Query
        component={TrackListRow}
        loadingComponent={TrackListRow}
        query={GetTrackVersion}
        variables={{ reference }}
        childProps={{ reference, ...childProps }}
        mapResult={mapResults}
    />;
}

TrackListWrapper.propTypes = {
    reference: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
};
