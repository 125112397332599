import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import Link from '@App/components/navigation/Link';
import { LICENCE_REQUESTS } from '@App/routes';
import LicenceRequestForm from '@App/components/model/licenceRequest/LicenceRequestForm';
import { navigate } from '@App/store/actions/navigation';
import AccessDenied from '@App/components/page/error/AccessDenied';

class LicenceRequestFormPage extends Component {
    static propTypes = {
        onSuccess: PropTypes.func.isRequired,
        canRequestLicence: PropTypes.bool.isRequired,
    };

    getForm() {
        const { onSuccess } = this.props;

        return <LicenceRequestForm onSuccess={onSuccess} />;
    }

    render() {
        const { canRequestLicence } = this.props;

        if (!canRequestLicence) {
            return <AccessDenied />;
        }

        return (
            <div className="content form-page">
                <div className="container-fluid">
                    <ul className="breadcrumb breadcrumb--dark">
                        <li className="breadcrumb__item">
                            <Link route={LICENCE_REQUESTS.name} confirm={I18n.t('page.licence_request_form.confirm')} className="link">
                                <i className="icon icon--chevron-left"></i>
                                {I18n.t('page.licence_request_form.back')}
                            </Link>
                        </li>
                    </ul>
                    <h1>{I18n.t('page.licence_request_form.title')}</h1>
                    <hr/>
                    {this.getForm()}
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        canRequestLicence: state.authentication?.permissions?.canRequestLicence === true,
    }),
    dispatch => ({
        onSuccess: id => dispatch(navigate(LICENCE_REQUESTS.name, undefined, `request-${id}`)),
    })
)(LicenceRequestFormPage);
