import { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Radio from '@App/components/form/Radio';
import Button from '@App/components/ui/Button';

export default class Form extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.func.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
        onSubmit: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        submitLabel: PropTypes.string,
        renderForm: PropTypes.func,
        validator: PropTypes.func,
        data: PropTypes.shape(),
        readOnly: PropTypes.bool,
        errors: PropTypes.shape(),
    };

    static defaultProps = {
        loading: false,
        success: false,
        submitLabel: null,
        validator: Form.isValid,
        renderForm: fields => fields,
        errors:{},
    };

    constructor(props) {
        super(props);

        const { fields, data } = props;

        this.state = Object.fromEntries(fields.map(field => {
            const key = field.name;
            const value = data && typeof data[key] !== 'undefined' ? data[key] : field.type.defaultProps?.value;

            return [key, value];
        }));

        this.onSubmit = this.onSubmit.bind(this);
        this.createField = this.createField.bind(this);
    }

    static isFieldValid(field, value) {
        const { validator, required } = field;

        if (typeof validator === 'function') {
            return validator(value);
        }

        if (required) {
            // required field should neither be an empty string, null/undefined or false:
            return !['', null, false, undefined].includes(value);
        }

        return true;
    }

    static isValid(fields, state) {
        return fields.every(field => Form.isFieldValid(field, state[field.name]));
    }

    onChange(key, value) {
        this.setState({ [key]: value });
    }

    onSubmit(event) {
        event.preventDefault();

        const { loading, success, onSubmit } = this.props;

        if (loading || success || !this.isValid()) {
            return;
        }

        onSubmit(this.state);
    }

    getId(field) {
        if (typeof field.id !== 'undefined') {
            return field.id;
        }

        if (field.type === Radio) {
            return `${this.props.name}_${field.name}_${field.choice}`;
        }

        return `${this.props.name}_${field.name}`;
    }

    getKey(field) {
        if (typeof field.key !== 'undefined') {
            return field.key;
        }

        if (field.type === Radio) {
            return `${field.name}_${field.choice}`;
        }

        return field.name;
    }

    getLabel(field) {
        if (typeof field.label !== 'undefined') {
            return field.label;
        }

        if (field.type === Radio) {
            return I18n.t(`form.${this.props.name}.${field.name}.${field.choice}`);
        }

        return I18n.t(`form.${this.props.name}.${field.name}.label`);
    }

    getHelp(field) {
        if (typeof field.help === 'boolean') {
            return field.help ? I18n.t(`form.${this.props.name}.${field.name}.help`) : null;
        }

        if (field.help !== 'undefined') {
            return field.help;
        }

        return null;
    }

    createField(field) {
        const { readOnly, errors } = this.props;
        const { type, name, ...properties } = field;

        delete properties.validator;

        return createElement(type, {
            ...properties,
            name,
            id: this.getId(field),
            key: this.getKey(field),
            label: this.getLabel(field),
            help: this.getHelp(field),
            readOnly: readOnly || properties.readOnly,
            value: this.state[name],
            onChange: this.onChange.bind(this, name),
            errors: errors[name],
        });
    }

    isValid() {
        return this.props.validator(this.props.fields, this.state);
    }

    render() {
        const { submitLabel, loading, success, fields, renderForm, readOnly } = this.props;
        const valid = this.isValid();

        return (
            <form onSubmit={this.onSubmit}>
                {renderForm(fields.map(this.createField))}
                <div className="row">
                    {readOnly ? null : <Button
                        type="submit"
                        className="btn btn--primary btn--full"
                        loading={loading}
                        disabled={loading || success || !valid}
                        label={submitLabel || I18n.t(`form.${this.props.name}.submit.label`)}
                    />}
                </div>
            </form>
        );
    }
}
