import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';

export default class Modal extends Component {
    static propTypes = {
        title: PropTypes.node.isRequired,
        onClose: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
        icon: PropTypes.string,
        classModal: PropTypes.string,
    };

    static defaultProps = {
        icon: null,
    };

    render() {
        const { title, icon, onClose, children, classModal } = this.props;

        return (
            <div className={`modal modal--show ${classModal}`}>
                <div className="modal__header">
                    { icon || title && (
                        <div className="title">
                            {icon ? <i className={`icon icon--${icon}`}></i> : null}
                            {title}
                        </div>
                    )}
                    <button className="close" onClick={onClose}>
                        <i className="icon icon--clear"></i>
                        <span className="sr-only">
                            {I18n.t('common.modal.close')}
                        </span>
                    </button>
                </div>
                <div className="modal__content">
                    {children}
                </div>
            </div>
        );
    }
}
