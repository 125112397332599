import { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import GraphQLUtils from '@App/utils/graphql';
import Form from '@App/components/form/Form';
import TextInput from '@App/components/form/TextInput';
import TrackListInput from '@App/components/form/TrackListInput';
import Mutation from '@App/components/api/Mutation';
import CreateLicenceRequest from '@App/api/mutation/CreateLicenceRequest.graphql';
import { onAddLicenceRequest } from '@App/api/store';
import FormUtils from '@App/utils/form';
import UrlInput from '@App/components/form/UrlInput';

class LicenceRequestForm extends Component {
    static propTypes = {
        id: PropTypes.string,
        loading: PropTypes.bool,
        errors: PropTypes.shape(),
        mutate: PropTypes.func.isRequired,
        onSuccess: PropTypes.func,
        licenceRequest: PropTypes.shape(),
        readOnly: PropTypes.bool,
        limitedPartnerName: PropTypes.string.isRequired,
        limitedPartnerEmail: PropTypes.string.isRequired,
    };

    static defaultProps = {
        id: null,
        errors: {},
        loading: false,
        onSuccess: null,
        licenceRequest: null,
        readOnly: false,
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.renderForm = this.renderForm.bind(this);

        this.fields = [
            // Limited Partner
            { type: TextInput, name: 'limitedPartnerName', required: true, readOnly: true },
            { type: TextInput, name: 'limitedPartnerEmail', readOnly: true },
            // Movie details
            { type: TextInput, name: 'title', required: true },
            { type: UrlInput, name: 'url' },
            // Tracks
            {
                type: TrackListInput,
                name: 'tracks',
                label: null,
                // validator: value => value.length > 0 && value.every(track => track.duration > 0)
            },
        ];
    }

    componentDidUpdate(prevProps) {
        const { id, onSuccess } = this.props;

        if (id && !prevProps.id && onSuccess) {
            return onSuccess(id);
        }
    }

    renderForm(form) {
        const fields = Object.fromEntries(form.map(field => [field.key, field]));

        return (
            <div>
                <h2>{I18n.t('form.licence_request.limitedPartner.label')}</h2>
                <div className="row">
                    <div className="col-sm-6">{fields.limitedPartnerName}</div>
                    <div className="col-sm-6">{fields.limitedPartnerEmail}</div>
                </div>

                <h2>{I18n.t('form.licence_request.movie.label')}</h2>
                <div className="row">
                    <div className="col-sm-6">{fields.title}</div>
                    <div className="col-sm-6">{fields.url}</div>
                </div>

                <h2>{I18n.t('form.licence_request.tracks')}</h2>
                {fields.tracks}
            </div>
        );
    }

    onSubmit(data) {
        const { mutate, loading, id } = this.props;

        if (loading || id !== null) {
            return;
        }

        mutate({ payload: {
            title: data.title,
            url: data.url,
            limitedPartner: {
                name: data.limitedPartnerName,
                email: data.limitedPartnerEmail,
            },
            tracks: data.tracks,
        } }, onAddLicenceRequest).catch(() => {
            FormUtils.scrollToFirstError();
        });
    }

    load(licenceRequest = null) {
        if (!licenceRequest) {
            return {
                limitedPartnerName: this.props.limitedPartnerName,
                limitedPartnerEmail: this.props.limitedPartnerEmail,
                tracks: [],
            };
        }

        return {
            title: licenceRequest.title,
            url: licenceRequest.url,
            limitedPartnerName: licenceRequest.limitedPartner.name,
            limitedPartnerEmail: licenceRequest.limitedPartner.email,
            tracks: licenceRequest.tracks.map(licenceTrack => ({
                reference: licenceTrack.version.reference,
            })),
        };
    }

    render() {
        const { id, loading, errors, licenceRequest, readOnly } = this.props;

        return <Form
            name="licence_request"
            fields={this.fields}
            renderForm={this.renderForm}
            loading={loading}
            success={id !== null}
            onSubmit={this.onSubmit}
            data={this.load(licenceRequest)}
            readOnly={readOnly}
            errors={errors}
        />;
    }
}

function mapResult(data) {
    return { id: data.licenceRequestCreate.id };
}

function mapLoading() {
    return { loading: true };
}

function mapError(error) {
    if (!error) {
        return { errors: {} };
    }

    const graphQLErrors = GraphQLUtils.errorsByPath(error.graphQLErrors);

    const tracksErrors = [].concat(
        graphQLErrors['tracks'],
        Object.entries(graphQLErrors)
            .filter(([path]) => /^tracks\[\d+\]\..+/.test(path))
            .reduce((carry, [, errors]) => {
                errors.forEach((error) => carry.includes(error) || carry.push(error));

                return carry;
            }, [])
    ).filter(Boolean);

    return { errors: {
        title: graphQLErrors['title'],
        url: graphQLErrors['url'],
        limitedPartnerName: graphQLErrors['limitedPartner.name'],
        limitedPartnerEmail: graphQLErrors['limitedPartner.email'],
        tracks: tracksErrors,
    } };
}

const LicenceRequestFormConnect = connect(
    (state) => ({
        limitedPartnerName: state.authentication?.profile?.organization?.name,
        limitedPartnerEmail: state.authentication?.profile?.email,
    })
)(LicenceRequestForm);

/**
 * GraphQL wrapped DownloadProjectPanel
 *
 * @param {Object} props
 */
export default function LicenceRequestFormWraper(props) {
    return <Mutation
        mutation={CreateLicenceRequest}
        component={LicenceRequestFormConnect}
        loadingComponent={LicenceRequestFormConnect}
        errorComponent={LicenceRequestFormConnect}
        mapResult={mapResult}
        mapLoading={mapLoading}
        mapError={mapError}
        childProps={props}
    />;
}
