import { useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { LOGIN, REGISTER } from '@App/routes';
import Modal from '@App/components/layout/Modal';
import ModalOverlay from '@App/components/layout/ModalOverlay';

export default function ForbiddenPlayerMessage(props) {
    useEffect(function () {
        /** WARNING: This is a hack to prevent SSR tests (with phpunit) to respond 500 :
         * this mode doesn't recognize 'document' JavaScript keyword  */
        if(typeof document !== 'undefined') {
            document.body.classList.add('no-scroll');
            return () => document.body.classList.remove('no-scroll');
        }
    });

    const { onClose } = props ;

    return (
        <ModalOverlay>
            <Modal
                onClose={onClose}
                classModal="register-modal"
            >
                <p dangerouslySetInnerHTML={{ __html: I18n.t('modal.forbidden.body') }}></p>
                <p className="small">{I18n.t('modal.forbidden.register')}</p>
                <a href={REGISTER.getUrl()} className="btn">
                    {I18n.t('modal.forbidden.register_link')}
                </a>
            </Modal>
        </ModalOverlay>
    );
}

ForbiddenPlayerMessage.propTypes = {
    onClose: PropTypes.func.isRequired,
};
